"use client";

import { memo, useMemo } from "react";
import { Navbar, Accordion } from "flowbite-react";
import equal from "react-fast-compare";

const NavbarGroup = memo(function NavbarGroup({
  icon,
  label,
  link,
  links,
  prefetch = true,
}) {
  const hasLinks = Array.isArray(links);

  // const Icon = typeof icon === "function" ? icon : () => <img src={icon} />;
  const Icon = useMemo(
    () => (typeof icon === "function" ? icon : () => <span>{icon}</span>),
    [icon]
  );

  const items = (hasLinks ? links : []).map((link) => (
    <Navbar.Link href={link.link} key={link.label} className="pl-2 my-1">
      <span className="w-8">{Icon(link?.icon)}</span>
      {link.label}
    </Navbar.Link>
  ));

  const item = (
    <Navbar.Link href={link} active={window.location.pathname === link}>
      <span className="w-8">{Icon(icon)}</span>
      {["QQQ", "SPY"].includes(label) ? "" : label}
    </Navbar.Link>
  );

  return hasLinks ? (
    <Accordion collapseAll className="divide-none border-none	">
      <Accordion.Panel>
        <Accordion.Title className="flex w-full items-center font-sans p-2 text-sm tracking-tighter text-white rounded-lg group focus:bg-brand-orange focus-hover:bg-brand-orange-dark focus:text-white active:text-white active:bg-brand-orange active-hover:bg-brand-orage-dark hover:bg-gray-800">
          <div className="text-white flex gap-x-2 items-center">
            <Icon />
            {label}
          </div>
        </Accordion.Title>
        <Accordion.Content className="gap-1 border-transparent">
          {items}
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  ) : prefetch ? (
    item
  ) : (
    <div data-turbo-prefetch="false">{item}</div>
  );
},
equal);

export default NavbarGroup;
