"use client";

import React from "react";
import { Sidebar } from "flowbite-react";

export default function SidebarGroup({
  icon,
  label,
  link,
  links,
  prefetch = true,
}) {
  const hasLinks = Array.isArray(links);

  const Icon = (i) => {
    return typeof i === "function" ? i : () => <span className='w-5'>{i}</span>;
  }

  const items = (hasLinks ? links : []).map((link) => (
    <Sidebar.Item icon={Icon(link?.icon)} href={link.link} key={link.label}>
      {link.label}
    </Sidebar.Item>
  ));

  const item = (
    <Sidebar.Item href={link} icon={Icon(icon)}>
      {label}
    </Sidebar.Item>
  );

  return hasLinks ? (
    <Sidebar.Collapse icon={Icon(icon)} label={label}>
      {items}
    </Sidebar.Collapse>
  ) : prefetch ? (
    item
  ) : (
    <div data-turbo-prefetch="false">{item}</div>
  );
}
