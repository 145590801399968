'use client';

import React, { memo } from 'react';

import { Sidebar, Navbar, Dropdown } from 'flowbite-react';
import { useHover } from '@uidotdev/usehooks';

import SidebarGroup from './sidebar_group';
import NavbarGroup from './navbar_group';

import RigdSymbolType from 'assets/images/design/rigd-symbol-type.png';
import RigdSymbol from 'assets/images/design/rigd-symbol.png';
import Og from 'assets/images/og.jpg';

import IconMenuClose from 'assets/images/left_nav/close.svg?react';
import IconUserMenu from 'assets/images/left_nav/user_menu.svg?react';
import IconNavbarToggle from 'assets/images/left_nav/navbar_toggle.svg?react';
import IconAdmin from 'assets/images/left_nav/admin.svg?react';
import IconAccount from 'assets/images/left_nav/account.svg?react';
import IconDailyStats from 'assets/images/left_nav/daily-stats.svg?react';
import IconDashboard from 'assets/images/left_nav/dashboard.svg?react';
import IconDiscord from 'assets/images/left_nav/discord.svg?react';
import IconDocs from 'assets/images/left_nav/docs.svg?react';
import IconHallOfGains from 'assets/images/left_nav/hall-of-gains.svg?react';
import IconHistorical from 'assets/images/left_nav/historical.svg?react';
import IconLiveFeed2 from 'assets/images/left_nav/live-feed-2.svg?react';
import IconLiveFeed from 'assets/images/left_nav/live-feed.svg?react';
import IconLogin from 'assets/images/left_nav/login.svg?react';
import IconLogout from 'assets/images/left_nav/logout.svg?react';
import IconQQQ from 'assets/images/left_nav/qqq.svg?react';
import IconSPY from 'assets/images/left_nav/spy.svg?react';
import IconStockFlow from 'assets/images/left_nav/stock-flow.svg?react';
import IconTwitter from 'assets/images/left_nav/twitter.svg?react';

import equal from 'react-fast-compare';

const sidebarTheme = {
  root: {
    base: 'h-screen bg-black relative z-10 border-r border-zinc-700 hidden lg:block',
    collapsed: {
      on: 'w-18',
      off: 'w-full',
    },
    inner: 'h-full overflow-y-auto overflow-x-hidden rounded py-4 bg-black',
  },
  collapse: {
    button:
      'group h-6 flex w-full items-center px-2 text-sm font-light font-normal text-white hover:bg-brand-orange-dark rounded-lg cursor-pointer',
    icon: {
      base: 'w-5 h-5',
      open: {
        off: '',
        on: '',
      },
    },
    list: 'space-y-2 py-2',
  },
  item: {
    base: 'h-6 flex w-full items-center justify-center p-2 text-sm font-light font-normal text-white hover:bg-brand-orange-dark rounded-lg group cursor-pointer',
    icon: {
      base: 'w-5 h-5',
    },
    collapsed: {
      insideCollapse: 'group w-full pl-8 transition duration-75',
      noIcon: 'collapse',
    },
  },
  itemGroup: {
    base: 'mt-4 space-y-4 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700',
  },
  items: {
    base: 'items-center px-2',
  },
  logo: {
    base: 'mb5 flex items-center px-2',
    img: 'h-10',
  },
};

const navbarTheme = {
  root: {
    base: 'lg:fixed top-0 left-0 z-50',
    inner: {
      base: 'flex lg:hidden w-full items-center justify-between mx-auto sm:px-8 px-2 sm:py-4 py-3 bg-black relative z-10',
    },
  },
  collapse: {
    list: 'space-y-2 mx-2',
    base: 'fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-black w-52 transform-none',
  },
  toggle: {
    base: 'block focus:outline-none text-brand-orange',
  },
  link: {
    active: {
      on: 'flex items-center p-2 text-sm tracking-tight text-white rounded-lg group bg-brand-orange text-white hover:bg-brand-orange-dark',
      off: 'flex items-center p-2 text-sm tracking-tight text-white rounded-lg group hover:bg-gray-100 dark:hover:bg-gray-800',
    },
  },
};

const dropdownTheme = {
  floating: {
    style: {
      auto: 'z-10 divide-y rounded-md shadow w-44 bg-black border border-zinc-600 px-2 block',
    },
    target: 'w-fit',
  },
};

function generateNavbar({ admin, current_user, guest_user, secondary, discord_url, docs_url }) {
  const navbarDataLoggedIn = [
    { label: 'Dashboard', icon: IconDashboard, link: '/dashboard' },
    { label: 'Hall of Gains', icon: IconHallOfGains, link: '/hall-of-gains' },
    { label: 'SPY', icon: IconSPY, link: '/flows/spy' },
    { label: 'QQQ', icon: IconQQQ, link: '/flows/qqq' },
    {
      label: 'Stock Flow',
      icon: IconStockFlow,
      links: secondary.map(f => ({ label: f, link: `/flows/${f}` })),
    },
    { label: 'Live Feed', icon: IconLiveFeed, link: '/alerts' },
    { label: 'Historical', icon: IconHistorical, link: '/alerts/historical' },
    { label: 'Daily Stats', icon: IconDailyStats, link: '/daily_stats' },
    { label: 'Labs', icon: '🧪', link: '/live/feed' },
  ];

  const navbarDataFooter = [
    { label: 'Discord', icon: IconDiscord, link: discord_url },
    { label: 'Docs', icon: IconDocs, link: docs_url },
    current_user
      ? null
      : {
          label: '@Rigd.ai',
          icon: IconTwitter,
          link: 'https://twitter.com/riggedai',
        },
    admin ? { label: 'Admin Panel', icon: IconAdmin, link: '/admin' } : null,
  ];

  const loginItem = [{ label: 'Login', icon: IconLogin, link: '/login' }];

  const navbarDataUser = guest_user
    ? loginItem
    : [
        { label: 'Account', icon: IconAccount, link: '/account' },
        { label: 'Logout', icon: IconLogout, link: '/logout', prefetch: false },
      ];

  const navbarMain = current_user ? [...navbarDataLoggedIn].filter(i => !!i) : [];

  const navbarFooter = current_user
    ? [...navbarDataUser, ...navbarDataFooter].filter(i => !!i)
    : [...navbarDataFooter].filter(i => !!i);

  const navbarFooterMobile = [...(current_user ? [] : loginItem), ...navbarDataFooter].filter(
    i => !!i,
  );

  return { navbarFooter, navbarMain, navbarFooterMobile, navbarDataUser };
}

const LeftNav = memo(function LeftNav({
  current_user,
  handle,
  guest_user,
  admin,
  secondary,
  discord_url,
  docs_url,
}) {
  const [ref, hovering] = useHover();

  const { navbarFooter, navbarMain, navbarFooterMobile, navbarDataUser } = generateNavbar({
    admin,
    current_user,
    guest_user,
    secondary,
    discord_url,
    docs_url,
  });

  return (
    <div ref={ref} data-turbo='false' className='z-50'>
      <div className='lg:fixed top-0 left-0'>
        <Sidebar collapsed={!hovering} aria-label='RIGD Sidebar' theme={sidebarTheme}>
          <Sidebar.Logo href='/' img={RigdSymbolType} imgAlt='RIGD Logo' />
          <Sidebar.Items>
            {navbarMain?.length > 0 && (
              <Sidebar.ItemGroup>
                {navbarMain.map(n => (
                  <SidebarGroup key={n.label} {...n} />
                ))}
              </Sidebar.ItemGroup>
            )}
            <Sidebar.ItemGroup>
              {navbarFooter.map(n => (
                <SidebarGroup key={n.label} {...n} />
              ))}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>
      <Navbar fluid theme={navbarTheme}>
        <div className='flex items-center'>
          <Navbar.Toggle barIcon={IconNavbarToggle} />
          <div className='block w-px h-5 bg-gray-100 bg-opacity-50 sm:mx-7 mx-3' />
          <Navbar.Brand as='a' href='/'>
            <img className='h-14' src={Og} />
          </Navbar.Brand>
        </div>
        <div className='flex items-center justify-end'>
          <Dropdown
            theme={dropdownTheme}
            renderTrigger={() => (
              <button className='flex items-center text-sm font-medium text-white rounded-full py-2 px-4 hover:text-brand-orange md:mr-0 focus:ring-1 hover:ring-brand-orange active:ring-brand-orange focus:ring-brand-orange focus:text-brand-orange'>
                <IconUserMenu />
                {handle || 'Your Profile'}
              </button>
            )}
            dismissOnClick={false}
          >
            {navbarDataUser.map(item => (
              <Dropdown.Item key={item.label} as='a' href={item.link} icon={item.icon}>
                {item.label}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>

        <Navbar.Collapse>
          <Navbar.Toggle
            barIcon={IconMenuClose}
            className='text-brand-orange bg-transparent hover:bg-gray-800 rounded-lg text-sm w-8 h-8 absolute top-3 right-3 inline-flex items-center justify-center'
          />
          <a
            className='md:p-0 flex items-center p-2 text-sm tracking-tight text-white rounded-lg group'
            href='/dashboard'
          >
            <img className='h-14 mt-10' src={RigdSymbol} />
          </a>

          {navbarMain.map(n => (
            <NavbarGroup key={n.label} {...n} />
          ))}
          <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700' />
          {navbarFooterMobile.map(n => (
            <NavbarGroup key={n.label} {...n} />
          ))}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}, equal);

export default LeftNav;
