import React from 'react'
import { createRoot } from 'react-dom/client'

import LeftNav from '../components/global/left_nav';

const render_node = document.getElementById('left-nav');
const root = createRoot(render_node);

const data_node = document.getElementById('left-nav-data');
const rootProps = JSON.parse(data_node.getAttribute('data'));

root.render(
  <React.StrictMode>
    <LeftNav {...rootProps} />
  </React.StrictMode>,
);
